import { trpc } from '@api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetTasks = () => {
  const { selectedCompany } = useSelectedCompany();

  const { isLoading, data } = trpc.getTasks.useQuery({
    companyId: selectedCompany.id,
  });

  const formatedData = {
    expiredTasks: data?.expiredTasks.map((task) => {
      const { dueDate, resolvedAt, ...remaingTaskProps } = task;
      return {
        ...remaingTaskProps,
        ...(resolvedAt && { resolvedAt: new Date(resolvedAt) }),
        dueDate: dueDate ? new Date(dueDate) : null,
      };
    }),
    pendingTasks: data?.pendingTasks.map((task) => {
      const { dueDate, resolvedAt, ...remaingTaskProps } = task;
      return {
        ...remaingTaskProps,
        ...(resolvedAt && { resolvedAt: new Date(resolvedAt) }),
        dueDate: dueDate ? new Date(dueDate) : null,
      };
    }),
    resolvedTasks: data?.resolvedTasks.map((task) => {
      const { dueDate, resolvedAt, ...remaingTaskProps } = task;
      return {
        ...remaingTaskProps,
        resolvedAt: new Date(resolvedAt!),
        dueDate: dueDate ? new Date(dueDate) : null,
      };
    }),
  };

  return {
    data: formatedData,
    isLoading,
  };
};
